<template>
    <section class="box">
        <nl-table
            ref="partnersTable"
            async
            :columns.sync="columns"
            :checked-rows.sync="checkedRows"
            :get-data="fetchUser"
            :actions="[
                { action: this.changeStatusValidate, label: 'Valider le partenaire' },
                {
                    action: this.changeStatusInvalidate,
                    label: 'Invalider le partenaire'
                }
            ]"
        >
            <template #partner_status="{ props }">
                <span
                    class="badge"
                    :class="{
                        'badge-darkorange': props.row[props.column.field] == null,
                        'badge-danger': props.row[props.column.field] === 'invalidate',
                        'badge-success': props.row[props.column.field] === 'validate'
                    }"
                >
                    {{ getPartnerStatus(props.row[props.column.field]) }}
                </span>
            </template>
            <template #partner_statusSearch="{ props }">
                <b-select v-model="props.filters['partner_status']" size="is-small">
                    <option value="">Tous</option>
                    <option value="validate">Validé</option>
                    <option value="invalidate">Non Validé</option>
                    <option value="null">Non défini</option>
                </b-select>
            </template>

            <template #date_inscription="{ props }">
                <span class="nowrap">
                    {{ moment(props.row.date_inscription).format('L') }}
                </span>
            </template>

            <template #email="{ props }">
                <router-link :to="'/admin/users/details?id=' + props.row.id">
                    {{ props.row[props.column.field] }}
                </router-link>
                <LoginAs :user-id="props.row.id" />
            </template>

            <template #sum_withdraw="{ props }">
                {{ props.row[props.column.field] ?? 0 | currency }}
            </template>

            <template #attestations="{ props }">
                <div v-if="props.row[props.column.field] && props.row[props.column.field].length > 0">
                    {{ $t('partnerAdmin.attestation.with') }}<br />
                    <i
                        ><small
                            v-if="
                                getLastAttestation(props.row.attestations) &&
                                getLastAttestation(props.row.attestations).status === 'validate'
                            "
                        >
                            Exp: {{ getLastAttestationExpiration(props.row.attestations) }}
                        </small></i
                    >
                </div>
                <div v-else>
                    {{ $t('partnerAdmin.attestation.without') }}
                </div>
            </template>

            <template #attestationsSearch="{ props }">
                <b-select v-model="props.filters['attestation']" size="is-small" placeholder="Avec / Sans">
                    <option value="">-</option>
                    <option value="with">
                        {{ $t('partnerAdmin.attestation.with') }}
                    </option>
                    <option value="without">
                        {{ $t('partnerAdmin.attestation.without') }}
                    </option>
                </b-select>
            </template>

            <template #attestations.status="{ props }">
                <div class="badge" :class="getLastAttestationBadge(props.row.attestations)">
                    {{ getLastAttestationStatus(props.row.attestations) }}
                </div>
            </template>

            <template #attestations.statusSearch="{ props }">
                <b-select
                    v-model="props.filters['attestation_status']"
                    expanded
                    size="is-small"
                    placeholder="Statut..."
                >
                    <option value="">Tous</option>
                    <option value="validate">Valide</option>
                    <option value="expired">Expiré</option>
                    <option value="refused">Refusé</option>
                </b-select>
            </template>

            <template #attestations.expiration="{ props }">
                <div
                    v-if="
                        getLastAttestation(props.row.attestations) &&
                        getLastAttestation(props.row.attestations).status == 'validate'
                    "
                    style="font-size: 13px"
                >
                    {{ moment(getLastAttestationExpiration(props.row.attestations)).format('L') }}
                </div>
            </template>

            <template #attestations.expirationSearch="{ props }">
                <b-input
                    v-model="props.filters[props.column.field]"
                    size="is-small"
                    type="date"
                    placeholder="Date d'expiration..."
                />
            </template>
        </nl-table>

        <CommercialProfile @saved="reload" />
        <Toaster ref="toasterdeux" />
    </section>
</template>

<script>
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex'
    import Toaster from '@/components/UI/Toaster'
    import CommercialProfile from '../../components/Admin/User/CommercialProfile.vue'
    import { EventManager } from '@/components/EventManager'

    export default {
        title: 'layout.partner-admin',
        name: 'PartnerAdmin',
        components: {
            CommercialProfile,
            Toaster
        },
        filters: {
            date(value) {
                return moment(value).format('DD-MM-YYYY')
            }
        },
        data() {
            return {
                isSearchable: true,
                showColumns: false,
                columns: [
                    {
                        label: this.$t('partnerAdmin.status.label'),
                        field: 'partner_status',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: this.$t('partnerAdmin.subscriptionDate'),
                        field: 'date_inscription',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        centered: false,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: this.$t('partnerAdmin.withdraw'),
                        field: 'sum_withdraw',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true
                    },
                    {
                        label: this.$t('user.firstname'),
                        field: 'firstname',
                        centered: false,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('user.lastname'),
                        field: 'lastname',
                        centered: false,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('user.phone'),
                        field: 'telephone',
                        centered: true,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: 'Skype',
                        field: 'skype',
                        centered: false,
                        sortable: true,
                        searchable: true
                    },
                    {
                        label: this.$t('user.attestation.attestation'),
                        field: 'attestations',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: "Statut de l'attestation",
                        field: 'attestations.status',
                        centered: true,
                        sortable: false,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },
                    {
                        label: "Expiration de l'attestation",
                        field: 'attestations.expiration',
                        centered: true,
                        sortable: false,
                        searchable: true,
                        custom: true,
                        customSearch: true
                    },

                    {
                        label: 'Siret',
                        field: 'siret',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        visible: false
                    },
                    {
                        label: 'Numéro de TVA',
                        field: 'tvaNumber',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        visible: false
                    },
                    {
                        label: 'Site(s)',
                        field: 'site',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        visible: false
                    },
                    {
                        label: 'Gains',
                        field: 'earn',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        visible: false
                    },
                    {
                        label: 'Parrainage',
                        field: 'sponsorship',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        visible: false
                    },
                    {
                        label: 'Commande(s)',
                        field: 'content',
                        centered: true,
                        sortable: true,
                        searchable: true,
                        visible: false
                    }
                ],

                defaultFilters: {
                    role: 'partenaire'
                },
                checkedRows: [],
                filters: null,
                sort: null,
                sortOrder: null,
                page: null,
                rows_per_page: 15,
                loading: false,
                bulkActionType: null
            }
        },
        computed: {
            ...mapGetters('users', { users: 'list' }),
            partnerStatus() {
                return {
                    undefined: this.$t('partnerAdmin.status.undefined'),
                    validate: this.$t('partnerAdmin.status.validate'),
                    invalidate: this.$t('partnerAdmin.status.invalidate')
                }
            }
        },
        methods: {
            ...mapActions('users', {
                getUsers: 'list',
                deleteUser: 'delete',
                exportUsers: 'export',
                updatePartnerStatus: 'updatePartnerStatus'
            }),
            fetchUser(params) {
                return new Promise(resolve => {
                    params.include = 'attestations'
                    params.filter = {
                        ...params.filter,
                        role: 'partenaire'
                    }
                    params.rowsPerPage = params.per_page

                    this.getUsers(params).then(data => {
                        resolve(data.users)
                    })
                })
            },
            viewCommercialProfile(data) {
                EventManager.$emit('onViewCommercialProfile', data)
            },
            reload(data) {
                this.fireToaster(data)
                this.$refs.partnersTable.loadAsyncData()
            },
            fireToaster({ data }) {
                this.$refs.toasterdeux.fire('success', data.message, this.$t('commande.validation.front.saved'))
            },
            getPartnerStatus(status) {
                if (!status) {
                    status = 'undefined'
                }
                return this.partnerStatus[status]
            },
            changeStatusValidate() {
                this.changeStatus('validate')
            },
            changeStatusInvalidate() {
                this.changeStatus('invalidate')
            },
            changeStatus(status) {
                let listIds = this.checkedRows.map(user => user.id)

                this.axios
                    .patch('/admin/users/bulk-status', {
                        userIds: listIds,
                        status: status
                    })
                    .then(data => {
                        this.reload(data)
                        this.checkedRows = []
                    })
            },
            getLastAttestation(attestations) {
                if (attestations) {
                    return attestations[attestations.length - 1]
                }
                return null
            },
            getLastAttestationStatus(attestations) {
                const last = this.getLastAttestation(attestations)
                if (last) {
                    if (last.status === 'new') {
                        return this.$t('partnerAdmin.attestation.status.new')
                    }
                    if (last.status === 'validate') {
                        return this.$t('partnerAdmin.attestation.status.validate')
                    }
                    if (last.status === 'expired') {
                        return this.$t('partnerAdmin.attestation.status.expired')
                    }
                    if (last.status === 'refused') {
                        return this.$t('partnerAdmin.attestation.status.refused')
                    }
                }
                return null
            },
            getLastAttestationExpiration(attestations) {
                const last = this.getLastAttestation(attestations)
                if (last) {
                    return last.expiration
                }
                return null
            },
            getLastAttestationBadge(attestations) {
                const last = this.getLastAttestation(attestations)
                if (last) {
                    if (last.status === 'new') {
                        return 'badge-darkorange'
                    }
                    if (last.status === 'validate') {
                        return 'badge-success'
                    }
                    if (last.status === 'expired') {
                        return 'badge-danger'
                    }
                    if (last.status === 'refused') {
                        return 'badge-danger'
                    }
                }
                return null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .table-prepend {
        display: flex;
        justify-content: space-between;

        .table-preprend-cols {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .btn {
                margin-bottom: 10px;
            }
        }

        .columnsChoice {
            position: relative;

            &-btn {
            }

            &-menu {
                position: absolute;
                right: 0;
                background-color: white;
                box-shadow: 0 0 6px #88888838;
                z-index: 5;
                padding: 20px 50px;

                label {
                    white-space: nowrap;
                }
            }
        }
    }

    .nowrap {
        white-space: nowrap;
    }

    small {
        font-size: 0.8em;
    }
</style>
